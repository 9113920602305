<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h3"
          >{{ readOnly ? $t("view_business") : $t("edit") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container class="pb-0 mb-0">
            <v-row>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="oemb2b.tenant_id"
                  disabled
                  dense
                  :items="clientsList.filter((r) => r.product == 'oem')"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_oem_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemb2b.business_name"
                  dense
                  :label="$t('business_name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemb2b.business_email"
                  dense
                  :label="$t('business_email_*')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemb2b.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="oemb2b.country_code"
                  dense
                  :items="countryCodeLists"
                  item-value="id"
                  item-text="name"
                  disabled
                  :label="$t('country_code_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemb2b.business_phone"
                  dense
                  type="number"
                  :label="$t('phone_number_*')"
                  :rules="[
                    rules.required,
                    rules.startFrom(3),
                    rules.min_length(10),
                    rules.max_length(10),
                  ]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="oemb2b.avg_vehicle_weight"
                  type="number"
                  dense
                  :label="$t('weight_capacity')"
                  :rules="[rules.float(2)]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-file-input
                  v-model="oemb2b.business_logo"
                  dense
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  :label="$t('business_logo_*')"
                  :rules="[rules.file_size]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-img
                  lazy-src="https://picsum.photos/id/11/10/6"
                  max-height="100"
                  max-width="100"
                  :src="oemb2b.logo_url"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0 mt-0">
          <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="!readOnly"
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(oemb2b)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    oemb2b: {
      type: Object,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],

      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      clientsList: "meta/clientsList",
    }),
  },
  async mounted() {},
  methods: {
    async updateItem(oem) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("oemBusiness/update", oem)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response.data.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
